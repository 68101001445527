// extracted by mini-css-extract-plugin
export var base = "typography-module--base--yrof+";
export var blockQuote = "typography-module--blockQuote---yLYy";
export var large = "typography-module--large--y+p-f";
export var macro = "typography-module--macro--ixSjl";
export var micro = "typography-module--micro--4fYlB";
export var paragraph = "typography-module--paragraph---kHwl";
export var responsiveTitle1 = "typography-module--responsiveTitle1--ZOeXB";
export var responsiveTitle2 = "typography-module--responsiveTitle2--ySzQe";
export var responsiveTitle3 = "typography-module--responsiveTitle3--v3-tV";
export var responsiveTitle4 = "typography-module--responsiveTitle4--z-i93";
export var responsiveTitle5 = "typography-module--responsiveTitle5--lESbj";
export var responsiveTitleHuge = "typography-module--responsiveTitleHuge--TDYTh";
export var small = "typography-module--small--CJ-EH";
export var title1 = "typography-module--title1--oWEwE";
export var title2 = "typography-module--title2--bj0Xz";
export var title3 = "typography-module--title3--J9AbZ";