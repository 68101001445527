// extracted by mini-css-extract-plugin
export var alignReset = "section-module--alignReset--y6vY4";
export var black = "section-module--black--6pfI3";
export var brickRed = "section-module--brickRed--6Aa-r";
export var center = "section-module--center--RlyMQ";
export var dark = "section-module--dark--car6I";
export var darkGreen = "section-module--darkGreen--13w-g";
export var deepBlue = "section-module--deepBlue--XYwoE";
export var flush = "section-module--flush--FkOYJ";
export var flushSides = "section-module--flushSides--ooYdJ";
export var globe = "section-module--globe--Le9u2";
export var green = "section-module--green--nU2FN";
export var isPageContent = "section-module--isPageContent--1q2WE";
export var lime = "section-module--lime--iSTkn";
export var noPadding = "section-module--noPadding---U+0z";
export var noPaddingBottom = "section-module--noPaddingBottom--tUFp5";
export var noPaddingTop = "section-module--noPaddingTop--tai6Q";
export var orange = "section-module--orange--dly4s";
export var peach = "section-module--peach---INo+";
export var pink = "section-module--pink--MFzMv";
export var purple = "section-module--purple--1Mlnl";
export var root = "section-module--root--jRTGT";
export var violet = "section-module--violet--guL12";
export var white = "section-module--white--glbXf";
export var yellow = "section-module--yellow--rCWdY";