// extracted by mini-css-extract-plugin
export var embedCode = "lightbox-module--embedCode--6sGhv";
export var figure = "lightbox-module--figure--Yj4MZ";
export var lightboxAfterOpen = "lightbox-module--lightboxAfterOpen--9jqBi";
export var lightboxBase = "lightbox-module--lightboxBase--Ii19b";
export var lightboxBeforeClose = "lightbox-module--lightboxBeforeClose--ENaE1";
export var lightboxBodyOpen = "lightbox-module--lightboxBodyOpen--hWe1p";
export var lightboxBullet = "lightbox-module--lightboxBullet--IhJqF";
export var lightboxCloseBtn = "lightbox-module--lightboxCloseBtn--HQ+Lm";
export var lightboxContent = "lightbox-module--lightboxContent--LgnUU";
export var lightboxHtmlOpen = "lightbox-module--lightboxHtmlOpen--UEHcE";
export var lightboxNextBtn = "lightbox-module--lightboxNextBtn--73RsB";
export var lightboxOverlay = "lightbox-module--lightboxOverlay--4m2CH";
export var lightboxPrevBtn = "lightbox-module--lightboxPrevBtn--r4LSU";