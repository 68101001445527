// extracted by mini-css-extract-plugin
export var addressInfo = "footer-module--addressInfo--5z0St";
export var base = "footer-module--base--0oNEr";
export var blockQuote = "footer-module--blockQuote--RbFRI";
export var branding = "footer-module--branding--7bEVO";
export var brandingImage = "footer-module--brandingImage--h0gcq";
export var columnLink = "footer-module--columnLink--kR+gE";
export var contactInfo = "footer-module--contactInfo--uk4q9";
export var container = "footer-module--container--RauMC";
export var footerBottom = "footer-module--footerBottom--Rc50y";
export var footerColumns = "footer-module--footerColumns--mzjEp";
export var footerContact = "footer-module--footerContact--73HTx";
export var footerCopyright = "footer-module--footerCopyright--aDwKv";
export var footerCopyrightLinks = "footer-module--footerCopyrightLinks--OmVdw";
export var footerCta = "footer-module--footerCta--MeO2c";
export var footerHeading = "footer-module--footerHeading--Q+SqS";
export var footerSchools = "footer-module--footerSchools--trYb4";
export var large = "footer-module--large--k4VAw";
export var macro = "footer-module--macro--KySwS";
export var micro = "footer-module--micro--ysu0u";
export var paragraph = "footer-module--paragraph--rBzuu";
export var responsiveTitle1 = "footer-module--responsiveTitle1--rbLF7";
export var responsiveTitle2 = "footer-module--responsiveTitle2--7W9pb";
export var responsiveTitle3 = "footer-module--responsiveTitle3--sdRC2";
export var responsiveTitle4 = "footer-module--responsiveTitle4--I7jle";
export var responsiveTitle5 = "footer-module--responsiveTitle5--Jy52C";
export var responsiveTitleHuge = "footer-module--responsiveTitleHuge--+We81";
export var root = "footer-module--root--GJBjC";
export var small = "footer-module--small--sOsfP";
export var socialIcons = "footer-module--socialIcons--S4BuW";
export var title1 = "footer-module--title1--I8Gqp";
export var title2 = "footer-module--title2--Xh3Rs";
export var title3 = "footer-module--title3--kZBuu";
export var wrapper = "footer-module--wrapper--st+Y9";